// constants.ts
import {
  BasePricingBlockProps,
  InternalCreditsPriceID,
  InternalSubscriptionPriceID,
} from "@/typings";
import { Raleway, Roboto, Roboto_Mono } from "next/font/google";
import { LocalSubscriptionPlan } from "@/modeltypings";

export const raleway = Raleway({
  subsets: ["latin"],
  display: "swap",
});
export const roboto_mono = Roboto_Mono({
  subsets: ["latin"],
  variable: "--font-roboto-mono",
  display: "swap",
});

export const roboto = Roboto({
  weight: "400",
  subsets: ["latin"],
  display: "swap",
});

namespace Constants {
  export const maxTextAreaHeightBeforeScrolling = 250;
  export const facebookBlueHex = "#1877F2";
  export const defaultBGColor = "bg-base-300";
  export const TASK_PAGE_SIZE = 10;
  export const SOCIAL_MAN_POSTS_PAGE_SIZE = 5;
  export const MIN_CREDIT_REDEMPTION_AMOUNT = 10000;
  export const CREDIT_TO_USD_RATION = 1 / 1000;
  export const PRESET_TAX = 30 / 100;
  export const allSizesStyleTextSmaller =
    "transition-all text-[10px] sm:text-xs md:text-sm lg:text-md";
  export const allSizesStyleText =
    "transition-all text-[12px] xs:text-xs sm:text-sm md:text-md lg:text-lg";
  export const allSizesMinusXSStyleText =
    "transition-all text-[12px] text-sm md:text-md lg:text-lg";
  export const allSizesStyleMDText =
    "transition-all text-[12px] xs:text-xs sm:text-sm md:text-md";
  export const allSizesStyleBadge =
    "transition-all badge-xs sm:badge-sm md:badge-md lg:badge-lg xl:badge-xl";
  export const allSizesStyleButton =
    "transition-all btn-xs sm:btn-sm md:btn-md lg:btn-lg";
  export const StripeThemeColorHex = "#5433FF";
  export const contactUsErrorMessage =
    "Something went wrong, please contact us here https://mltask.com/contact";

  export const contactUsError = new Error(contactUsErrorMessage);
  export const spanLoadingStyle =
    "loading-md loading loading-dots text-secondary";
  export const buttonLoadingStyle =
    "loading-md loading loading-dots text-secondary";

  export const dropShadowStyle = "drop-shadow-[4px_4px_3px_rgba(0,0,0,0.25)]";
  export const dropShadowLightStyle =
    "drop-shadow-[2px_2px_1px_rgba(0,0,0,0.5)]";

  export const queueStatusClass = "bg-gradient-to-r from-neutral to-gray-500";
  export const executingStatusClass =
    "bg-gradient-to-r from-teal-300 to-green-300";
  export const failedStatusClass = "bg-gradient-to-r from-pink-500 to-error";
  export const payAsYouGoOptions: BasePricingBlockProps[] = [
    {
      cost: 1,
      name: "Explorer",
      badgeText: "",
      credit: 1000,
      themeColor: "accent",
      bulletPoints: ["One time payment"],
      internalPriceID: InternalCreditsPriceID.Credits_1000,
      CTA: "Buy Now",
    },
    {
      cost: 7,
      name: "Entrepreneur",
      badgeText: "Most Popular",
      credit: 7000,
      themeColor: "primary",
      bulletPoints: ["One time payment"],
      internalPriceID: InternalCreditsPriceID.Credits_7000,
      CTA: "Buy Now",
    },
    {
      cost: 20,
      name: "Pro",
      badgeText: "",
      credit: 20000,
      themeColor: "info",
      bulletPoints: ["One time payment"],
      internalPriceID: InternalCreditsPriceID.Credits_20000,
      CTA: "Buy Now",
    },
  ];
  export const socialManMonthlyPricingOptionsDict: Map<
    InternalSubscriptionPriceID,
    BasePricingBlockProps
  > = new Map<InternalSubscriptionPriceID, BasePricingBlockProps>([
    [
      InternalSubscriptionPriceID.Subscription_5000,
      {
        cost: 5,
        name: "Starter",
        badgeText: "",
        credit: 6000,
        themeColor: "accent",
        bulletPoints: [
          "Unlimited number of Posts",
          "Connect all your social networks",
          "Get 5500 monthly credits to use ai to optimize your posts (10% discount)",
          "Get all Starter features for MLTask",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_5000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_15000,
      {
        cost: 15,
        name: "Pro",
        badgeText: "",
        // badgeText: "Most Popular",
        credit: 18000,
        themeColor: "primary",
        bulletPoints: [
          "Unlimited number of Posts",
          "Connect all your social networks",
          "Get 18000 monthly credits to use ai to optimize your posts (20% discount)",
          "Get all Pro features for MLTask",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_15000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_100000,
      {
        cost: 100,
        name: "SUPER",
        badgeText: "",
        credit: 120000,
        themeColor: "info",
        bulletPoints: [
          "Get 120000 credits monthly (20% discount)",
          "Ad Free Browsing",
          "Private Tasks",
          "Monetiezable presets",
          "15% cheaper task cost",
          "Special badge, customizations as a valued customer",
          "Get custom links for your preset names",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_100000,
        CTA: "Subscribe Now",
      },
    ],
  ]);
  export const socialManMonthlyPricingOptions: BasePricingBlockProps[] = [
    socialManMonthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_5000
    )!,
    socialManMonthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_15000
    )!,
    // monthlyPricingOptionsDict.get("Subscription_100000")!,
  ];
  export const monthlyPricingOptionsDict: Map<
    InternalSubscriptionPriceID,
    BasePricingBlockProps
  > = new Map<InternalSubscriptionPriceID, BasePricingBlockProps>([
    [
      InternalSubscriptionPriceID.Subscription_5000,
      {
        cost: 5,
        name: "Starter",
        badgeText: "",
        credit: 6000,
        themeColor: "accent",
        bulletPoints: [
          "Get 5500 credits monthly (10% discount)",
          "Private Tasks",
          "Monetiezable presets",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_5000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_15000,
      {
        cost: 15,
        name: "Pro",
        badgeText: "Most Popular",
        credit: 18000,
        themeColor: "primary",
        bulletPoints: [
          "Get 18000 credits monthly (20% discount)",
          // "Ad Free Browsing",
          "Private Tasks",
          "Monetiezable presets",
          // "10% cheaper task cost",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_15000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_100000,
      {
        cost: 100,
        name: "SUPER",
        badgeText: "",
        credit: 120000,
        themeColor: "info",
        bulletPoints: [
          "Get 120000 credits monthly (20% discount)",
          "Ad Free Browsing",
          "Private Tasks",
          "Monetiezable presets",
          "15% cheaper task cost",
          "Special badge, customizations as a valued customer",
          "Get custom links for your preset names",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_100000,
        CTA: "Subscribe Now",
      },
    ],
  ]);
  export const monthlyPricingOptions: BasePricingBlockProps[] = [
    monthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_5000
    )!,
    monthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_15000
    )!,
    // monthlyPricingOptionsDict.get("Subscription_100000")!,
  ];
  export function localSubPlanToPriceID(
    localSubscriptionPlan: LocalSubscriptionPlan
  ): InternalSubscriptionPriceID | undefined {
    if (localSubscriptionPlan == LocalSubscriptionPlan.NO_PLAN)
      return undefined;
    if (localSubscriptionPlan == LocalSubscriptionPlan.SUBSCRIPTION_5000)
      return InternalSubscriptionPriceID.Subscription_5000;
    if (localSubscriptionPlan == LocalSubscriptionPlan.SUBSCRIPTION_15000)
      return InternalSubscriptionPriceID.Subscription_15000;
    if (localSubscriptionPlan == LocalSubscriptionPlan.SUBSCRIPTION_100000)
      return InternalSubscriptionPriceID.Subscription_100000;
  }
  export function priceIDToLocalSubPlan(
    localSubscriptionPlan: InternalSubscriptionPriceID
  ): LocalSubscriptionPlan {
    if (localSubscriptionPlan == InternalSubscriptionPriceID.Subscription_5000)
      return LocalSubscriptionPlan.SUBSCRIPTION_5000;
    if (localSubscriptionPlan == InternalSubscriptionPriceID.Subscription_15000)
      return LocalSubscriptionPlan.SUBSCRIPTION_15000;
    if (
      localSubscriptionPlan == InternalSubscriptionPriceID.Subscription_100000
    )
      return LocalSubscriptionPlan.SUBSCRIPTION_100000;
    return LocalSubscriptionPlan.SUBSCRIPTION_5000;
  }
}

export default Constants;
